"use strict";

$(document).ready(function () {
  if (window.location.pathname == "/") {
    handleSmoothScroll();
  }

  handleDropDown();
  handleNavbar();
});

function handleSmoothScroll() {
  var sections = $('section'),
      nav = $('#navbar');
  $(window).on('scroll', function () {
    var cur_pos = $(this).scrollTop();
    sections.each(function () {
      var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

      if (cur_pos >= top - 100 && cur_pos <= bottom) {
        nav.find('a').parent().removeClass('active');
        sections.removeClass('active');
        $(this).addClass('active');
        nav.find('a[href="#' + $(this).attr('id') + '"]').parent().addClass('active');
      }
    });
  }); // Add smooth scrolling to all links

  $(".smoothscroll").on('click', function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault(); // Store hash

      var hash = this.hash; // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area

      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 600, function () {
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if

  });
}

function handleDropDown() {
  var acc = document.getElementsByClassName("has-subitems");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      /* Toggle between adding and removing the "active" class,
      to highlight the button that controls the panel */
      this.classList.toggle("active");
      /* Toggle between hiding and showing the active panel */

      console.log(this.children[this.children.length - 1]);
      var panel = this.children[this.children.length - 1];

      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }
}

function handleNavbar() {
  var navbarToggler = $(".c-header__nav-toggler");
  var navbarMenu = $(".c-header__nav");
  navbarToggler.on('click', function (event) {
    navbarMenu.toggleClass("active");
  });
}
"use strict";